@import '../../../../../styles/variables';

.container {
  padding-bottom: 10px;
  padding-top: 0;
  color: $dark-gray;
  font-size: 15px;
  line-height: 19px;
  text-transform: uppercase;
  vertical-align: top;
}

.filterButton {
  margin-top: -4px;
}

.icon {
  font-size: 10px;
  margin-left: 10px;
}
