.container {
  display: flex;
  align-items: center;
  padding: 3rem 2rem 2rem;
  border-bottom: 1px solid #e8ecf1;
}

.info {
  margin-left: 2rem;
  color: #1963b0;
  font-size: 1.1rem;
  font-weight: 500;
}

.btnBack {
  color: #1963b0;
}
