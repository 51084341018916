.container {
  width: '100%';
}

.table {
  border-spacing: 0 10px;
  border-collapse: separate;
  table-layout: fixed;
  // height: 100%;

  &Container {
    height: calc(100vh - 200px);
  }

  th,
  td {
    border-bottom: none;
    font-size: 17px;
    line-height: 22px;
  }
}

.loader {
  width: 100%;
  display: flex;
  justify-content: center;
}

.empty {
  text-align: center;
  font-size: 18px;
}
