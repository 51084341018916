@import '../../../../styles/variables';

.container {
  position: relative;
  width: 70%;
  min-height: 400px;
}

.centerBlock {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
}

.header {
  display: flex;
  align-items: center;
  padding: 14px 24px;
  border-bottom: 1px solid $light-gray;

  .title {
    font-size: 25px;
    font-weight: 500;
    color: $primary-color;
    margin: 0;
  }

  .icon {
    position: absolute;
    right: 14px;
    top: 14px;
  }
}

.content {
  padding: 16px;
}

@media only screen and (min-width: 600px) {
  .content {
    padding: 40px;
  }
}

.basicInfoWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.avatar {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.name {
  font-size: 17px;
  color: $form-input-color;
}

.jobTitle {
  font-size: 14px;
  color: $dark-gray;
}

.section {
  margin-top: 20px;
}

.sectionTitle {
  font-size: 12px;
  font-weight: 600;
  color: $dark-gray;
  padding: 0 8px 0 8px !important;
  text-transform: uppercase;
}

.primaryTitle {
  font-size: 14px;
  color: black;
}

.secondaryTitle {
  font-size: 12px;
  color: $form-input-color;
}

.additionalInfo {
  font-size: 10px;
  color: $dark-gray;
}

.workExpWrap {
  display: flex;
  align-items: center;
}

.workExpThumbnailWrap {
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: $white;
  border: 1px solid $light-gray;
  margin-right: 10px;
}

.workExpThumbnail {
  width: 100%;
  height: auto;
}

.attachmentRow {
  display: flex;
}

.attachment {
  margin-left: 6px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;

  .LinkButton {
    font-size: 14px;
    line-height: 18px;
    text-decoration: underline;
    color: #1963b0;
  }
}
