@import '../../styles/variables';

.label {
  display: block;
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  color: $form-input-label-color;
  text-align: left;
  transition: 0.25s color ease-in-out;

  &Error {
    color: $alert-color;
  }
}
