.container {
  // @extend .page-content;
}

.header {
  // @extend .bordered;
}

.centerBlock {
  // @extend .center-block;
  // @extend .medium;
  // @extend .pt-40;
  // @extend .pl-15;
  // @extend .pr-15;
}

.welcomeText {
  // @extend .mb-40;
  // @extend .mt-40;
  display: none;
  @media screen and (min-width: 40em) {
    display: block !important;
  }
}

.tacText {
  // @extend .mb-60;
  // @extend .mt-40;
  display: block;
  @media screen and (min-width: 40em) {
    display: none !important;
  }
}

.htmlContent {
  // @extend .fade-in;
  // @extend .block-color-gray;
}
