@import '../../styles/variables';

.dp-checkbox {
  position: relative;
  cursor: pointer;
  padding: 10px 0;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  &-small {
    font-size: 14px;
  }

  &-normal {
    font-size: 16px;
  }

  &-large {
    font-size: 24px;
  }

  * {
    outline: none;
  }

  &-disabled {
    user-select: none;
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }

  &-label {
    width: calc(100% - 38px);
    line-height: 20px;
    text-align: left;
    font-family: $form-input-font-family;
  }

  &-input {
    position: relative;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 0 12px 0 0;
    outline: none;
    border: none;
    background-color: $white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:before {
      transition: transform 0.35s cubic-bezier(0.45, 2.2, 0.5, 0.75);
      transform: rotate(-45deg) scale(0, 0);

      content: '';

      position: absolute;
      left: 6px;
      top: 7px;
      z-index: 1;

      width: 8px;
      height: 4px;

      border: 1px solid $white;
      border-top-style: none;
      border-right-style: none;
    }

    &:after {
      background-color: $white;
      border: none;
    }

    &:checked {
      &:before {
        transform: rotate(-45deg) scale(1, 1);
      }

      &:after {
        background-color: $primary-color;
        border: 1px solid $primary-color;
      }
    }

    &:after {
      transition: 0.25s background-color cubic-bezier(1, -0.46, 0, 1.53) 0s,
        0.3s cubic-bezier(1, -0.46, 0, 1.53) 0s;
      content: '';
      position: absolute;
      margin: 0;
      left: 0;
      width: 20px;
      height: 20px;
      background: #fff;
      border-radius: 50%;
      border: 1px solid $dark-gray;
      cursor: pointer;
    }
  }
}

.dp-checkbox-wrapper {
  & > div:first-of-type {
    width: 50px;
  }
  & > div:last-of-type {
    width: calc(100% - 50px);
  }
}
