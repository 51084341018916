.container {
  padding: 2rem 7rem;
  border-bottom: 1px solid #e8ecf1;
}

.mb {
  margin-bottom: 2rem;
}

.textRight {
  text-align: right;
}
