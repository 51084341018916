@import '../../styles/variables';

.container {
  display: flex;
  flex-direction: row;
  height: 100vh;

  & > aside {
    width: 100%;

    @media screen and (min-width: 40em) {
      width: 50%;
    }
  }
}

.leftSide {
  display: none;
  width: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 1rem 2.5rem;

  @media screen and (min-width: 40em) {
    display: block !important;
  }
}

.rightSide {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  overflow: auto;
  overflow-x: hidden;

  @media screen and (min-width: 40em) {
    padding: 1rem 2rem;
  }

  @media screen and (min-width: 64em) {
    padding: 1rem 6.25rem;
  }
}

.formContainer {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 24rem;

  animation: fadeIn 0.4s ease-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.header {
  margin-bottom: 2.5rem;

  h1 {
    font-size: 1.625rem;
    margin-bottom: 0;
  }

  h4 {
    color: $medium-gray;
    margin-top: 1rem;
  }
}
