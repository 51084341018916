.Progress {
  width: 100%;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  &Line {
    height: 100%;
    background-color: #1963b0;
  }
}
