@import '../../styles/variables';

.labelStatus {
  border-radius: 3px;
  padding: 2px 5px;
  text-transform: uppercase;
  margin-left: 20px;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
}

.labelNeedReview {
  background: #ffeed2;
  color: $status-need-review;
}

.labelReviewed {
  background: #c7f0ca;
  color: $status-review;
}

.labelCompleted {
  background: $status-completed-bg;
  color: $status-completed;
}

.labelNotFilled {
  background: $status-completed-bg;
  color: $status-not-filled;
}

.statusReviewed {
  color: $status-review;
}

.statusNeedReview {
  color: $status-need-review;
}

.statusPendingMatching {
  color: $status-pending-matching;
}
