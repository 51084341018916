@import '../../../styles/variables';

.title {
  font-size: 17px;
  line-height: 22px;
  font-weight: 500;
  color: $primary-color;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.subtitle {
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  color: $dark-gray;
  margin-bottom: 8px;
}

.text {
  font-size: 17px;
  color: $form-input-color;
}
