@import '../../../../../styles/variables';

.formArrayLabel {
  color: $dark-gray;
  font-size: 16px;
  margin-bottom: 8px;
  margin-top: 16px;
}

.formArrayActions {
  text-align: right;
}
