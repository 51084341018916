@import '../../../../../styles/variables';

.container {
  box-shadow: 0px 2px 2px #e8ecf1;
  background-color: $white;
  border-radius: 3px;
  cursor: pointer;
  max-height: 86px;

  td {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.shiftTitle {
  font-size: 17px;
  line-height: 22px;
  font-weight: bold;
}

.shiftDate {
  color: $form-input-color;
  font-size: 17px;
  line-height: 22px;
}

.shiftTimeWrap {
  display: flex;
  align-items: center;
}

.shiftTimeIcon {
  color: $dark-gray;
  margin-right: 8px;
}

.shiftTime {
  color: $form-input-color;
  font-size: 15px;
}

.location {
  color: $form-input-color;
  font-size: 17px;
}

.department {
  color: $form-input-color;
  font-size: 17px;
  display: flex;
  align-items: center;
}

.department img {
  max-height: 20px;
  color: $primary-color;
  margin-right: 8px;
}

.matchedWrap {
  display: flex;
  align-items: center;
  color: $form-input-color;
  font-size: 17px;
}

.matchedAvatar {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.statusWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.status {
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
}

.arrow {
  font-size: 12px;
  color: $dark-gray;
}
