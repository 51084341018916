@import '../../styles/variables';

.sidebarNavigation {
  width: 300px;
  background-color: $white;
  height: 100%;

  .logo {
    display: flex;
    margin-left: 1rem;
  }
}

.navigation {
  &Header {
    display: flex;
    align-items: center;
    padding: 3.125rem;
  }

  &MenuItems {
    display: flex;
    flex-direction: column;
  }

  &MenuItem {
    font-size: 15px;
    display: flex;
    align-items: center;
    padding: 0.875rem 2.25rem;
    text-decoration: none;
    border-left: 5px solid $white;
    svg path {
      fill: $dark-gray;
    }

    &Icon {
      margin-right: 1rem;
    }

    &Text {
      color: $dark-gray;
      transition: all 0.2s ease-in-out 0s;
    }

    &Active {
      svg path {
        fill: $primary-color;
      }

      .navigationMenuItemText {
        color: $primary-color;
      }
      color: $primary-color;
      border-left: 5px solid #1963b0;
    }
  }
}
