@import '../../../styles/variables';

.container {
  min-height: 100vh;
  margin-bottom: -70px;
}

.header {
  border-width: 1px;
  border-style: solid;
  border-color: $light-gray;
  .primary {
    border-color: $primary-color;
  }
}

.centerBlock {
  margin-left: auto;
  margin-right: auto;

  &.small {
    max-width: 400px;
  }

  &.medium {
    max-width: 550px;
  }

  &.large {
    max-width: 800px;
  }
  padding: 40px 15px 0 15px;
}

.welcomeText {
  margin-bottom: 40px;
  margin-top: 40px;

  display: none;
  @media screen and (min-width: 40em) {
    display: block !important;
  }
}

.tacText {
  margin-bottom: 60px;
  margin-top: 40px;
  // @extend .mb-60;
  // @extend .mt-40;
  display: block;
  @media screen and (min-width: 40em) {
    display: none !important;
  }
}

.lineBreak {
  display: inline-block;
  width: 4px;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;

  &.small {
    max-width: 400px;
  }

  &.medium {
    max-width: 550px;
  }

  &.large {
    max-width: 800px;
  }

  padding: 15px 15px 0 15px;
}
