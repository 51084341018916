@import '../../styles/variables';

.dp-radio-button {
  position: relative;
  cursor: pointer;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  line-height: 40px;
  font-family: $form-input-font-family;

  > div {
    display: flex;
    align-items: center;
  }

  &-disabled {
    user-select: none;
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }

  &-input {
    position: relative;
    outline: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px $dark-gray;
    background: #fff;
    margin: 0 10px 0 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: box-shadow 0.35s cubic-bezier(1, -0.46, 0, 1.53);
    &:before {
      content: '';
      position: absolute;
      top: calc(50% - 4px);
      left: calc(50% - 4px);
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: transparent;
      transition: background-color 0.15s cubic-bezier(1, -0.46, 0, 1.53),
        transform 0.35s cubic-bezier(1, -0.46, 0, 1.53);
      transform: scale(0);
    }

    &:checked {
      box-shadow: inset 0 0 0 2px $primary-color;
      &:before {
        background-color: $primary-color;
        transform: scale(1);
      }
    }
  }
}
