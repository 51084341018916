@import '../../../styles/variables';

.root {
  background-color: $white;
  color: #a9b3be;
  opacity: 1;
  font-size: 14px;
  line-height: 18px;
  text-transform: none;
  padding: 11px 27px;
  min-height: 40px;
}

.wrapper {
  text-align: center;
  font-size: 14px;
}

.selected {
  color: $primary-color;
  background-color: $select-tab-background;
  border-radius: 4px;
}
