@import '../../../../styles/variables';

.dropdown {
  border-radius: 3px;
  background-color: $white;
  box-shadow: 0 2px 2px $light-gray;
  padding-left: 15px;
  padding-right: 13px;
  border: none;

  &:hover {
    border: none;
    border-radius: 3px;
    background-color: $light-gray;
  }

  &Text {
    margin-right: 1rem;
  }
}

.popupContainer {
  min-width: 180px;
}
