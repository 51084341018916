.container {
  min-height: 80vh;
}

.centerBlock {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
