@import '../../../styles/variables';

.container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 1rem;
}

.name {
  color: $primary-color;
  font-size: 1.5rem;
  padding: 15px;
}

.secondary {
  padding: 15px;
  padding-top: 0;
}
