.modalActions {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;

  &Right {
    display: flex;
  }

  &Button {
    margin-left: 10px;
  }
}
