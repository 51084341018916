.container {
  text-align: right;
  width: 260px;
}

.buttonGroup {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 16px;
  span {
    font-size: 12px;
  }
}

.btnReset {
  transform: translate(-20px);
  span {
    text-transform: uppercase;
  }
}
