@import '../../styles/variables';

.dp-modal {
  position: relative;
  width: 100%;
  max-height: 100vh;

  overflow: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 5px;
    background: $light-gray;
    border-radius: 5px;
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: $medium-gray;
  }

  &.thin-scroll {
    &::-webkit-scrollbar {
      width: 3px;
    }
  }

  .icon {
    position: absolute;
    right: 14px;
    top: 14px;

    z-index: 2;
    cursor: pointer;
    // @include transition();
    &:hover {
      transform: rotate(90deg);
    }
  }
}

.footer {
  display: flex;
  border-top: 1px solid $light-gray;
  padding: 10px 20px;
  background-color: $extra-light-gray;
  border-bottom-left-radius: $global-radius;
  border-bottom-right-radius: $global-radius;

  & > button:not(:last-of-type) {
    margin-right: 10px;
  }

  &Center {
    justify-content: center;
  }

  &Left {
    justify-content: flex-start;
  }

  &Right {
    justify-content: flex-end;
  }
}

.entering-modal {
  animation: showModalCard 0.2s ease-in-out;
}

.exiting-modal {
  animation: hideModalCard 0.15s ease-in-out;
}

@keyframes showModalCard {
  0% {
    transform: translate3d(0, -10px, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes hideModalCard {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(0, -10px, 0);
    opacity: 0;
  }
}
