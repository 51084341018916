@import '../../../../styles/variables';

.datePicker {
  width: 300px;
}

.dateRangePicker {
  padding: 1rem 1rem 0 1rem;
}

.datePickerWrap {
  margin-bottom: 10px;
}

.dateValueWrap {
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: $light-gray;
  border-radius: 3px;
}

.dateValueIcon {
  margin-right: 8px;
}

.dateValueText {
  font-size: 14px;
  color: $form-input-color;
}

.filterActions {
  display: flex;
  justify-content: space-between;
  margin: 1.25rem 0;
}

.datePeriods {
  padding: 1rem 0;
}
