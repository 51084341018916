@import '../../../styles/variables';

.indicator {
  display: none;
}

.root {
  display: flex;
  align-items: center;
}

.borderLeft {
  border-left-style: solid;
  border-left-width: 0.5px;
  border-left-color: $light-gray;
}

.borderRight {
  border-right-style: solid;
  border-right-width: 0.5px;
  border-right-color: $light-gray;
}
