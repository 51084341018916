@import '../../../../styles/variables';

.item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &.md {
    padding: 10px 15px;
    margin-bottom: 15px;

    @media (min-width: 768px) {
      min-height: 60px;
      padding: 10px 35px;
    }
  }

  &.sm {
    min-height: 30px;
    padding: 5px 10px;
    margin-bottom: 5px;
  }

  .iconLeft {
    display: flex;
    align-items: center;
    flex-grow: 1;

    &Container {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      @media (min-width: 768px) {
        margin-right: 35px;
      }
    }
  }
}
