@import '../../../../styles/variables';

.container {
  position: relative;
  width: 70%;
  min-height: 400px;
}

.centerBlock {
  width: 100%;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  display: flex;
  align-items: center;
  padding: 14px 24px;
  border-bottom: 1px solid $light-gray;

  .title {
    font-size: 25px;
    font-weight: 500;
    color: $primary-color;
    margin: 0;
  }

  .icon {
    position: absolute;
    right: 14px;
    top: 14px;
  }
}

.sectionSubtitle {
  font-size: 12px;
  font-weight: 500;
  color: $dark-gray;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.section {
  margin-bottom: 24px;
}

.department {
  display: flex;
  align-items: center;
}

.locationAddress {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;

  a {
    text-decoration: none;
    color: $form-input-color;
  }

  img {
    margin-top: 3px;
    margin-right: 5px;
  }

  span {
    font-size: 15px;
    color: $form-input-color;
    margin-left: 8px;
  }
}

.registeredNurse {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.avatarBadge {
  &:after {
    width: 10px;
    height: 10px;
    background-color: $alert-color;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    border: 2px solid $white;
    content: '';
  }
}

.registeredNurseDetail {
  margin-left: 10px;
}

.nurseName {
  font-size: 17px;
  color: $form-input-color;
}

.shiftDate {
  font-size: 17px;
  color: $form-input-color;
}

.shiftTime {
  display: flex;
  align-items: center;
  span {
    font-size: 15px;
    color: $form-input-color;
    margin-left: 8px;
  }
}

.serviceFeeRow {
  display: flex;
  justify-content: space-between;
  font-size: 17px;
  color: $form-input-color;
  margin-bottom: 4px;
}

.divider {
  height: 1px;
  background-color: $light-gray;
  margin-top: 8px;
  margin-bottom: 8px;
}

.total {
  font-weight: 500;
}

.totalValue {
  font-weight: 500;
  color: $primary-color;
}

.note {
  font-size: 14px;
  color: $dark-gray;
  margin-top: 10px;
  margin-bottom: 20px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .LinkButton {
    font-size: 14px;
    line-height: 18px;
    text-decoration: underline;
    color: #1963b0;
  }
}

.btnCancel {
  margin-right: -16px;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  color: #e33a4f;
}
