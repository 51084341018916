.container {
  padding: 2rem 7rem;
}

.divider {
  height: 1px;
  background-color: #e8ecf1;
  margin: 2rem 0;
}

.note {
  font-size: 0.875rem;
  color: #a9b3be;
  margin-top: 1rem;
}
