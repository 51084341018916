@import '../../styles/variables';

$offset: 187;
$duration: 1.4s;

.spinner {
  animation: rotator $duration linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: calc($offset/4);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}

.dp-spinner {
  position: relative;
  padding: 30px 15px;
  min-height: 60px;
  text-align: center;

  .path {
    stroke: $primary-color;
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash $duration ease-in-out infinite;
  }

  .circle {
    display: inline-block;
  }

  &.full-page {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.51);
    z-index: 5000;
    .circle {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}

.dp-linear-progress {
  background: lighten($primary-color, 59%);
  position: relative;
  height: 2px;
  overflow: hidden;
  -webkit-animation: start 0.3s ease-in;
  animation: start 0.3s ease-in;
  width: 100%;
  display: block;
}

.dp-linear-progress .bar {
  position: absolute;
  background: $primary-color;
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}

.dp-linear-progress .bar1 {
  -webkit-animation: progressLinearMovement 2.5s infinite;
  animation: progressLinearMovement 2.5s infinite;
  -webkit-animation-delay: 0;
  animation-delay: 0;
}

.dp-linear-progress .bar2 {
  left: -100%;
  -webkit-animation: progressLinearMovement 2.5s infinite;
  animation: progressLinearMovement 2.5s infinite;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

@keyframes start {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 20px;
    opacity: 1;
  }
}

@keyframes end {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 2px;
    opacity: 1;
  }
}

@keyframes progressLinearMovement {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}
