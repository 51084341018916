@import '../../../../styles/variables';

.container {
  position: relative;
  width: 70%;
  min-height: 400px;
}

.header {
  display: flex;
  align-items: center;
  padding: 14px 24px;
  border-bottom: 1px solid $light-gray;

  .icon {
    position: absolute;
    right: 14px;
    top: 14px;
  }
}

.content {
  padding-top: 20px;
  padding-bottom: 40px;
}

.centerBlock {
  width: 100%;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sectionSubtitle {
  font-size: 12px;
  font-weight: 500;
  color: $dark-gray;
  margin-bottom: 8px;
}

.section {
  margin-bottom: 24px;
}

.shiftDate {
  font-size: 17px;
  color: $form-input-color;
}

.shiftTime {
  display: flex;
  align-items: center;
  span {
    font-size: 15px;
    color: $form-input-color;
    margin-left: 8px;
  }
}

.registeredNurse {
  display: flex;
}

.registeredNurseDetail {
  margin-left: 10px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
}

.btnClose {
  margin-right: 16px;
}
